.bigTitle {
  font-size: 2.5rem;
  color: var(--color-background-razzmatazz);
  margin-bottom: 2rem;
}

.successTitle {
  color: var(--color-feedback-success-light);
}

.errorTitle {
  color: var(--color-feedback-error);
}
