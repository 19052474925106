.main-header {
  display: flex;
  justify-content: center;
  position: fixed !important;
  top: -74px;
  transition: top 300ms ease-in-out;
}

.main-header > header > div > div > div svg {
  height: 40px;
}

.main-header > header > div > div:nth-child(2) button * {
  cursor: pointer !important;
}

.main-header > header > div > div:nth-child(2) button:nth-child(1):hover {
  border: 1px solid var(--color-background-tenne) !important;
}

.main-header > header > div > div:nth-child(2) button:nth-child(1):hover > div > svg > path {
  stroke: var(--color-background-tenne) !important;
}

.main-header > header > div > div:nth-child(2) button:nth-child(1):hover > div span {
  color: var(--color-background-tenne) !important;
}

.main-header > header > div > div:nth-child(2) button:nth-child(2):hover {
  background-color: var(--color-background-christi) !important;
  border: 1px solid var(--color-background-christi) !important;
}

.show {
  background-color: white !important;
  top: 0px;
}

.popUpContainer {
  top: 99px;
  right: 25%;
}

.popUpCorner {
  top: 89px;
  right: 28%;
}

.backgroundOverlay {
  background: rgba(0, 0, 0, 0.40);
  position: absolute !important;
  height: 100vh !important;
  width: 100vw !important;
  top: 0px;
}

.buttonCursor {
  cursor: initial;
}

.modalSize {
  min-height: 50% !important;
  max-height: 50% !important;
}

.modal__container--mobile article {
  max-height: 331px !important;
  min-height: 331px !important;
  overflow: hidden;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.modal__container--mobile-safari article {
  max-height: 45vh !important;
  min-height: 45vh !important;
  overflow: hidden;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.modal__container--mobile {
  overflow: hidden;
}
