.container > div > div:nth-child(2) {
  margin-top: -4%;
}

.input__code {
  margin-right: .5rem;
}

.input__code > input {
  min-height: 44px !important;
}

.text__error {
  margin: .75rem 0 0 1rem;
}

.input__error {
  border: 0.5px solid var(--color-feedback-error);
}

.input__success {
  border: 0.5px solid var(--color-feedback-success-light);
}

.buttonCursor {
  cursor: initial;
}

.text__desc {
  text-align: center;
}

.text__desc > span {
  font-weight: 600;
}

.buttonBackground:hover {
  background-color: #6DA300 !important;
  transition: all .4s ease;
  -webkit-transition: all .4s ease;
}

.input__height {
  min-height: 44px !important;
}

@media (max-width: 600px) {
  .container > div > div:nth-child(2) {
    margin-top: -15%;
  }
}

.bussiness-modal>article>article>div:nth-child(1) span {
  display: none !important;
}