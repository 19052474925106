.input_dropdown {
  border: 0.5px solid var(--color-text-silver-chalice);
  border-radius: var(--border-radius-md) !important;
  height: 44px;
  padding: 12px 16px;
  position: relative !important;
}

.input_dropdown--active>div>div>p {
  color: var(--color-text-silver-chalice);
}

.input_container {
  display: flex;
  gap: 8px;
}

.input_container input{
  max-height: 44px !important;
}

.input_label {
  background-color: var(--color-base-white);
  color: var(--color-text-silver-chalice);
  font-size: var(--font-size-sm);
  padding: 0 4px;
  position: absolute !important;
  top: -7px;
  width: auto !important;
}

.input_phone {
  align-items: center;
  border: 0.5px solid var(--color-text-silver-chalice);
  border-radius: var(--border-radius-md) !important;
  display: flex;
  height: 44px;
  justify-content: center;
  padding: 0 8px;
  position: relative !important;
  width: 80px !important;
}

.input_container--success,
.input_container--success input {
  border-color: var(--color-feedback-success-light);
}

.input_container--error,
.input_container--error input {
  border-color: var(--color-feedback-error) !important;
}

.text__error {
  font-size: var(--font-size-sm);
  padding-left: 16px;
  margin-top: 7px;
}