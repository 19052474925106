.toogle__giftcard {
  min-width: 512px;
  max-width: 512px;
  min-height: 36px;
  max-height: 36px;
}

.toogle__giftcard > div {
  background: var(--color-background-white) !important;
  border: 0.5px solid var(--color-background-dark-orange)
}

.toogle__giftcard-active {
  background: var(--color-background-dark-orange) !important;
  color: var(--color-text-white) !important;
  font-weight: var(--font-weight-medium);
  z-index: 1;
  width: 51% !important;
}

.toogle__giftcard-active:hover {
  background-color: #F87911 !important;
  transition: all .4s ease !important;
  -webkit-transition: all .4s ease !important;
}

.toogle__giftcard-active:active {
  background-color: #F7A73E !important;
}

.toogle__giftcard-inactive {
  background: var(--color-text-white) !important;
  font-weight: var(--font-weight-semibold);
}

.toogle__giftcard > div p {
  animation: none !important;
}

.input__giftcard-form {
  padding: 11px 16px;
  border: 0.5px solid var(--color-neutral-gray-input)
}

.text__message {
  margin: .75rem 0 0 1rem;
}

.text__error {
  margin: .75rem 0 0 1rem;
}

.input__error {
  border: 0.5px solid var(--color-feedback-error);
}

.input__success {
  border: 0.5px solid var(--color-feedback-success-light);
}


.selector__container {
  border-radius: var(--border-radius-md) !important;
  border: 0.5px solid var(--color-neutral-gray-input);
  font-size: 14px;
  line-height: var(--line-height-lg);
  max-height: 40px;
  min-height: 40px;
  padding: 8px 26px 8px 16px;
  width: -webkit-fill-available;
  margin-top: 0px;
  position: relative !important;
}

.selector__container > div > div > p {
  color: var(--color-neutral-gray-input) !important;
}

.selector__container svg > path {
  stroke: var(--color-neutral-gray-input) !important;
}

.selector__container > label {
  background-color: var(--color-background-white);
  color: var(--color-neutral-gray-input);
  max-height: 18px;
  position: absolute;
  font-size: 12px;
  top: -13px;
  left: 8px;
  line-height: var(--line-height-lg);
  padding: 0px 5px;
  pointer-events: none;
  transition: all 100ms ease;
}

.option__selected select {
  color: var(--color-alvi-neutral-black);
}

.selector__success {
  border: 0.5px solid var(--color-feedback-success-light);
}

.selector__success > div > div > p {
  color: var(--color-text-black) !important;
}

.button__cursor {
  cursor: not-allowed;
}

.label__margin {
  margin-right: .5rem;
}

.tooltiptextRewrited {
  margin-left: -165px !important;
}

.tooltiptextRewrited::after {
  right: 9% !important;
}

.clickableButton:active {
  background-color: var(--color-background-dark-orange) !important;
  color: var(--color-base-white) !important;
}

.infoContainer {
  display: flex;
  align-items: center;
  background-color: #F6FFE4;
  padding: 6px;
  border-radius: var(--border-radius-xs) !important;
}

.infoContent {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-normal);
}

.infoContent > span {
  font-weight: var(--font-weight-semibold);
  cursor: pointer;
  color: var(--color-background-citrus);
}

@media (width <= 39.9375em) {
  .toogle__giftcard {
    min-width: 100%;
    max-width: 100%;
  }
}
